// Sample irame
// <iframe src="https://www.google.com/maps/d/embed?mid=1U1vFKjYdeeGYPGk74z0BTCq0EHJd91rZ" width="640" height="480"></iframe>

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const MapPage = ({ location, pageContext }) => {
  const mapUrl = 'https://www.google.com/maps/d/embed?mid=1U1vFKjYdeeGYPGk74z0BTCq0EHJd91rZ'
  const apiKey = 'AIzaSyBmgNK9uGT87jJQQNrQWF4f_M7dp71uctE'
  const { isAmp } = pageContext

  return (
    <>
      {isAmp && (
        <Helmet>
          <script async custom-element="amp-iframe" src="https://cdn.ampproject.org/v0/amp-iframe-0.1.js" />
        </Helmet>
      )}
      <MetaData
        data={{}}
        location={location}
        type="article"
      />
      <Layout isAmp={isAmp}>
        <h1>Resources Map</h1>
        <p>This map displays various services people experiencing econmic insecurity can access throughout the city. It has been updated to reflect the organizations’ responses to COVID-19.</p>
        {isAmp ? (
          <amp-iframe width="640"
            height="480"
            title="Philly resources"
            layout="responsive"
            sandbox="allow-scripts allow-same-origin allow-popups"
            frameborder="0"
            src={`${mapUrl}&key=${apiKey}`}>
            <amp-img
              layout="intrinsic"
              src="/images/resolve-logo-640x480.png"
              width="640"
              height="480"
              placeholder />
          </amp-iframe>
        ) : (
          <iframe width="640"
            height="480"
            title="Philly resources"
            layout="responsive"
            sandbox="allow-scripts allow-same-origin allow-popups"
            frameborder="0"
            src={`${mapUrl}&key=${apiKey}`}>
          </iframe>
        )}
      </Layout>
    </>
  )
}

MapPage.propTypes = {
  location: PropTypes.object.isRequired
}

export default MapPage
